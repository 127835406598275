
.hourglass-container {
    position: relative;
    /* Use fixed positioning */
    top: 0;
    /* Align to the top of the viewport */
    left: 0;
    /* Align to the left of the viewport */
    right: 0;
    /* Align to the right of the viewport */
    bottom: 0;
    /* Align to the bottom of the viewport */
    display: flex;
    /* Use flexbox to center contents */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    background: rgba(255, 255, 255, 0.8);
    /* Optional: light background for visibility */
    z-index: 1000;
    height: 80vh;
    /* Ensure it is above other content */
}

.hourglass-container svg {
    animation: rotate 2s linear infinite;
    /* Optional animation for the SVG */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


