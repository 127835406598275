.highlight-hover {
    background-color: rgba(204, 229, 255, 1) !important;
    /* Hover color */
}

/* Stripe every alternate group (1&2, 3&4, etc.) */
.table-striped-group tbody tr:nth-child(4n+1),
.table-striped-group tbody tr:nth-child(4n+2) {
    background-color: rgba(230, 242, 255, 1);
    /* Stripe color */
}