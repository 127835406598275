//
// Scroll
//

// Customize native scrollbars only for desktop mode
@include media-breakpoint-up(lg) {

  main,
  span,
  ol,
  ul,
  pre,
  div {
    // Firefox
    scrollbar-width: auto; // Change from thin to auto for average width

    // Webkit
    &::-webkit-scrollbar {
      width: var(--#{$prefix}scrollbar-size, 10px); // Default to 10px if not set
      height: var(--#{$prefix}scrollbar-size, 10px); // Default to 10px if not set
    }

    &::-webkit-scrollbar-track {
      background-color: var(--#{$prefix}scrollbar-track-color, #f1f1f1); // Default track color if not set
    }

    &::-webkit-scrollbar-thumb {
      @include border-radius(var(--#{$prefix}scrollbar-size, 10px)); // Default to 10px if not set
      background-color: var(--#{$prefix}scrollbar-color, #888); // Default thumb color if not set
    }

    // Default color
    @include scrollbar-color(var(--#{$prefix}scrollbar-color, #888), // Default thumb color if not set
      var(--#{$prefix}scrollbar-hover-color, #555) // Default hover color if not set
    );
  }
}

// Scroll
.scroll,
.hover-scroll,
.hover-scroll-overlay {
  overflow: scroll;
  position: relative;

  // Tablet & mobile modes
  @include media-breakpoint-down(lg) {
    overflow: scroll;
  }
}

.scroll-x,
.hover-scroll-x,
.hover-scroll-overlay-x {
  overflow-x: scroll;
  position: relative;

  // Tablet & mobile modes
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
  }
}

.scroll-y,
.hover-scroll-y,
.hover-scroll-overlay-y {
  overflow-y: scroll;
  position: relative;

  // Tablet & mobile modes
  @include media-breakpoint-down(lg) {
    overflow-y: scroll;
  }
}

.hover-scroll,
.hover-scroll-overlay,
.hover-scroll-x,
.hover-scroll-overlay-x,
.hover-scroll-y,
.hover-scroll-overlay-y {
  @include scrollbar-color(var(--#{$prefix}scrollbar-color, #888), var(--#{$prefix}scrollbar-hover-color, #555)); // Ensure scrollbar color is not transparent
}

// Utilities
.scroll-ps {
  padding-left: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-ms {
  margin-left: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-mb {
  margin-bottom: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-pe {
  padding-right: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-me {
  margin-right: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-px {
  padding-left: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
  padding-right: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}

.scroll-mx {
  margin-left: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
  margin-right: var(--#{$prefix}scrollbar-size, 10px) !important; // Default to 10px if not set
}